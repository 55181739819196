<template>
  <index :by-invoice="true" />
</template>

<script>

import Index from './Index.vue'

export default {
  name: 'TenderListByInvoice',
  components: { Index },
}
</script>
